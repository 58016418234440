import FeaturesMixin from "@underwriters/src/mixins/features.mixin";

const getYearAfterDate = function(dt) {
  const yearAfter = new Date(dt);
  yearAfter.setFullYear(dt.getFullYear() + 1);
  return yearAfter;
};

export const localPolicies = {
  state: () => ({
    programmeStateId: 0,
    name: "",
    localPolicies: [],
    localContactDetails: [],
    instructions: [],
    policyLimit: null,
    emailAddress: "",
    partnerName: "",
    clientName: "",
    clientRef: "",
    underwriterName: "",
    brokerName: "",
    referenceNumber: "",
    inceptionDate: new Date(),
    expiryDate: getYearAfterDate(new Date()),
    dateOfRate: new Date(),
    displayedDateOfRate: new Date(),
    dateUnavailable: new Date(),
    completionStatus: "prospect",
    currencyRate: 1.0,
    mpolPremium: null,
    hasErrors: false,
  }),
  mutations: {
    setProgrammeStateId(state, payload) {
      state.programmeStateId = payload.id;
    },
    setLocalPolicies(state, payload) {
      state.localPolicies = payload.localPolicies;
    },
    setContactDetails(state, payload) {
      state.localContactDetails = payload.contactDetails;
    },
    setContactDetail(state, payload) {
      const contact = state.localContactDetails.find(
        cont => cont.id === payload.id
      );
      if (contact) {
        contact.data[payload.col].value = payload.value;
      }
    },
    removeContact(state, payload) {
      const contacts = state.localContactDetails;
      const cont = contacts.find(cont => cont.id === payload.id);
      cont.visible = false;

      // Need to set the rowspan of the country cell to 1, as it
      // no longer spreads to the broker row
      const idNum = payload.id.replace("broker", "");
      const clientRow = contacts.find(row => row.id === "client" + idNum);
      clientRow.data[0].rowspan = 1;
      state.localContactDetails = contacts;
    },
    removeBrokerRows(state, payload) {
      const newContacts = [];
      state.localContactDetails.forEach((cont, ind) => {
        if (payload.rows.indexOf(ind + 1) !== -1) {
          cont.data[0].rowspan = 1;
        }
        if (payload.rows.indexOf(ind) !== -1) {
          cont.visible = false;
        }

        newContacts.push(cont);
      });
      state.localContactDetails = newContacts;
    },
    applyCoverData(state, payload) {
      state.localPolicies.forEach(country => {
        payload.covers.forEach(
          template => {
            if (country.covers[template.id] === undefined) {
              return;
            }
            country.covers[template.id].included = template.data.included ? 1 : 0;
          }
        );
      });
    },
    swapPolicyCovers(state, payload) {
      payload.covers.forEach(cover => {
        state.localPolicies.forEach(
          cap => {
            if (cap[cover.id] === undefined) {
              console.log(`cover ${cover} not found on local policy`);
              return;
            }
            return (cap.covers[cover.id].included = cover.included)
          }
        );
      });
    },
    swapPolicyCover(state, payload) {
      state.localPolicies.forEach(cap => {
        if (cap[payload.id] === undefined) {
          console.log(`cover ${payload.id} not found on local policy`);
          return;
        }
        cap.covers[payload.id].included =
          cap.covers[payload.id].included === 0 ? 1 : 0;
      });
    },
    updatePolicyCoverageFromTemplate(state, payload) {
      state.localPolicies.forEach(country => {
        country.covers = {};
        payload.covers.forEach(cover => {
          country.covers[cover.id] = {};
          // TODO: Add this to the main system wiki?
          //  Allowing a cover to be included locally is being mocked with this env value
          const allowInclude =
            Math.random() < process.env.VUE_APP_MOCK_COVER_ALLOW_INCLUDE_PC;
          payload.fields.forEach(field => {
            if (field === "included") {
              country.covers[cover.id][field] = cover.data[field] ? 1 : 0;
            } else if (field === "allowInclude" || field === "allowExclude") {
              country.covers[cover.id][field] =
                field === "allowInclude" ? allowInclude : !allowInclude;
            } else {
              country.covers[cover.id][field] = cover.data[field];
            }
          });
        });
      });
    },
    addPolicyCover(state, payload) {
      state.localPolicies.forEach(country => {
        country.covers[payload.cover.id] = payload.cover.data;
      });
    },
    setPolicyLimit(state, payload) {
      state.policyLimit = payload.policyLimit;
    },
    setOverallPolicyDeductible(state, payload) {
      state.overallPolicyDeductible = payload.overallPolicyDeductible;
    },
    setPolicyDetail(state, payload) {
      if (payload.emailAddress || payload.emailAddress === "") {
        state.emailAddress = payload.emailAddress;
      }
      if (payload.partnerName || payload.partnerName === "") {
        state.partnerName = payload.partnerName;
      }
      if (payload.clientName || payload.clientName === "") {
        state.clientName = payload.clientName;
      }
      if (payload.clientRef || payload.clientRef === "") {
        state.clientRef = payload.clientRef;
      }
      if (payload.underwriterName || payload.underwriterName === "") {
        state.underwriterName = payload.underwriterName;
      }
      if (payload.brokerName || payload.brokerName === "") {
        state.brokerName = payload.brokerName;
      }
      if (payload.referenceNumber || payload.referenceNumber === "") {
        state.referenceNumber = payload.referenceNumber;
      }
      // TODO: Check here what format the date is...
      if (payload.inceptionDate) {
        state.inceptionDate = payload.inceptionDate;

        // If there is no expiry date and there is an inception date, default to the inception date + 1 year
        if(payload.expiryDate === null) {
          state.expiryDate = getYearAfterDate(state.inceptionDate);
        }
      }
      if (payload.expiryDate) {
        state.expiryDate = payload.expiryDate;
      }
      if (payload.dateOfRate) {
        state.dateOfRate = new Date(payload.dateOfRate);
      }
      if (payload.mpolPremium) {
        state.mpolPremium = payload.mpolPremium;
      }
    },
    setProgrammeName(state, payload) {
      state.name = payload.name;
    },
    resetProgramme(state) {
      state.policyLimit = null;
      state.name = "";
      state.underwriterName = "";
      state.brokerName = "";
      state.clientName = "";
      state.clientRef = "";
      state.referenceNumber = "";
      state.completionStatus = "prospect";
      state.programmeStateId = 0;
      state.partnerName = '';
      state.inceptionDate = new Date();
      state.localContactDetails = [];
      state.localPolicies = [];
      state.mpolPremium = null;
    },
    setProgrammeSubmitted(state) {
      state.completionStatus = "submitted";
    },
    loadLocalPoliciesStateFromStorage(state, payload) {
      state.programmeStateId = payload.programmeStateId;
      state.name = payload.name;
      state.localPolicies = payload.localPolicies;
      state.localContactDetails = payload.localContactDetails;
      state.policyLimit = payload.policyLimit;
      state.emailAddress = payload.emailAddress;
      state.partnerName = payload.partnerName;
      state.clientName = payload.clientName;
      state.clientRef = payload.clientRef;
      state.underwriterName = payload.underwriterName;
      state.brokerName = payload.brokerName;
      state.referenceNumber = payload.referenceNumber;
      state.mpolPremium = payload.mpolPremium;
      if (payload.inceptionDate) {
        state.inceptionDate = new Date(payload.inceptionDate);
      } else {
        state.inceptionDate = new Date();
      }
      if (payload.expiryDate && payload.length > 0) {
        state.expiryDate = new Date(payload.expiryDate);
      } else {
        state.expiryDate = getYearAfterDate(state.inceptionDate);
      }

      if (payload.dateOfRate && typeof payload.dateOfRate === "string") {
        // Date will have been received from the API as a string and
        // needs converting to a Date object
        const doR = payload.dateOfRate.split("T")[0].split("-");
        state.dateOfRate = new Date(
          parseInt(doR[0]),
          parseInt(doR[1]) - 1,
          parseInt(doR[2])
        );
      } else {
        state.dateOfRate = new Date();
      }

      state.completionStatus = payload.completionStatus;
    },
    setInstructionData(state, payload) {
      const localPolicy = state.localPolicies.find(
        lpol => lpol.options.country.id === payload.id
      );

      if (Object.hasOwnProperty.call(localPolicy.options, payload.field)) {
        localPolicy.options[payload.field].userValue = payload.value;
      } else {
        console.error(
          `Field ${payload.field} was not found on the local policy ` +
            `with id ${payload.id}, could not update`,
          localPolicy
        );
      }
    },
    validatePolicies(state) {
      let invalidPolicies = state.localPolicies.filter(policy =>
          !(policy.options.finc.userValue === true ||
              policy.options.fos.userValue === true ||
              policy.options.nonAdmitted.userValue === true ||
              policy.options.localPolicy.userValue === true)
      );

      if(invalidPolicies.length > 0) {
        state.localPolicies.forEach(policy => {
          policy.hasErrors = false;
        });

        invalidPolicies.forEach(policy => {
          state.localPolicies.find(localPolicy => localPolicy === policy).hasErrors = true;
        });

        state.hasErrors = true;
      } else {
        state.localPolicies.forEach(policy => {
          policy.hasErrors = false;
        });
        state.hasErrors = false;
      }
    },
    setLocalPoliciesValue(state, payload) {
      if (payload.field && payload.value) {
        state[payload.field] = payload.value;
      }
    },
    setPolicyCurrencyData(state, payload) {
      state.currencyRate = payload.currencyRate
        ? payload.currencyRate
        : state.currencyRate;
      const received = new Date(payload.dateOfRate + "T00:00:00Z");
      const requested = new Date(state.dateOfRate);
      requested.setHours(0, 0, 0, 0);
      if (received.valueOf() !== requested.valueOf()) {
        state.dateUnavailable = state.dateOfRate;
      } else {
        state.dateUnavailable = null;
      }
      state.displayedDateOfRate = received;
      state.dateOfRate = received;
    },
    setPolicySelectedWikiItem(state, { countryId, wikiItem }) {
      const localPolicy = state.localPolicies.find(policy => policy.options.country.country_id == countryId);
      if (!localPolicy) {
        console.error(
          "Tried to set selected wiki item for local policy with country ID", 
          countryId, 
          "but one couldn't be found!"
        );
        return;
      }
      if (wikiItem == null) {
        localPolicy.selectedWikiItem = null;
        return;
      }
      let selectedWikiItem = {countryId, ...wikiItem}
      localPolicy.selectedWikiItem = selectedWikiItem;
    }
  },
  actions: {
    setPolicyDetail({ commit }, payload) {
      commit("setPolicyDetail", payload);
    },
    validatePolicies({ commit }) {
      commit("validatePolicies");
    },
    createLocalPoliciesFromWikiData({ commit, dispatch }, payload) {
      const localPolicies = [];
      for (let country of payload.wikiData) {
        localPolicies.push(country);
      }
      commit("setLocalPolicies", { localPolicies: localPolicies });
      dispatch("generateContactDetails");
    },
    generateContactDetails({ state, commit }) {
      let rows = [];
      let id = 0;
      for (let data of state.localPolicies) {
        // Client contact info row with country name (rowspan=2
        rows.push({
          id: "client" + id,
          visible: true,
          data: [
            {
              type: "text",
              rowspan: 2,
              value: data.options.country.value,
              id: id + 1
            },
            { type: "text", rowspan: 1, value: "Client", id: id + 2 },
            { type: "text", rowspan: 1, value: "", id: id + 3 },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Client Name",
              id: id + 4
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Client Company Name",
              id: id + 5
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Client Address",
              id: id + 6
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Client Telephone",
              id: id + 7
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Client Email",
              id: id + 8
            }
          ]
        });
        id += 9;
        // Broker contact info row without country name
        rows.push({
          id: "broker" + (id - 9),
          visible: true,
          data: [
            { type: "text", rowspan: 1, value: "Broker", id: id + 1 },
            { type: "button", rowspan: 1, value: "", id: id + 2 },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Broker Name",
              id: id + 3
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Broker Company Name",
              id: id + 4
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Broker Address",
              id: id + 5
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Broker Telephone",
              id: id + 6
            },
            {
              type: "input",
              rowspan: 1,
              value: "",
              placeholder: "Broker Email",
              id: id + 7
            }
          ]
        });
        id += 8;
      }
      commit("setContactDetails", { contactDetails: rows });
    },
    duplicateLocalPolicy({ state, commit, dispatch }, payload) {
      const countryName = payload.countryId.replace(/\d/, "");
      const countries = state.localPolicies.filter(
        country => country.options.country.value === countryName
      );
      // Don't continue if we couldn't find any countries with this name...
      if (countries.length === 0) {
        return;
      }
      // nextId is the highest ID for this country + 1
      const nextId =
        parseInt(
          countries.reduce(
            (carry, country) =>
              carry > country.options.country.id.replace(countryName, "")
                ? carry
                : country.options.country.id.replace(countryName, ""),
            0
          )
        ) + 1;
      const newCountry = JSON.parse(JSON.stringify(countries[0]));
      newCountry.options.country.id = countryName + nextId;
      const localPolicies = JSON.parse(JSON.stringify(state.localPolicies));
      localPolicies.push(newCountry);
      commit("setLocalPolicies", { localPolicies: localPolicies });
      // Rebuild the contact details table with the new country
      dispatch("generateContactDetails");
    },
    removeLocalPolicy({ state, commit, dispatch }, payload) {
      let localPolicies = JSON.parse(JSON.stringify(state.localPolicies));
      const remCountry = localPolicies.find(
        country => country.options.country.id === payload.countryId
      );
      localPolicies = localPolicies.filter(
        country => country.options.country.id !== payload.countryId
      );
      commit("setLocalPolicies", { localPolicies: localPolicies });
      // If is the last copy of this country to be removed, deselect it from the country screen/list
      if (
        localPolicies.filter(
          country =>
            country.options.country.value === remCountry.options.country.value
        ).length === 0
      ) {
        dispatch("selectDeselectCountry", {
          countryId: remCountry.options.country.country_id,
          checked: false
        });
      }
      dispatch("generateContactDetails");
    },
    removeBrokerContact({ commit }, payload) {
      commit("removeContact", payload);
    },
    applyCoverDataToLocalPolicies({ commit, rootGetters }) {
      commit("applyCoverData", { covers: rootGetters.selectedTemplate.covers });
    },
    swapPolicyCovers({ commit, rootGetters }) {
      commit("swapPolicyCovers", {
        covers: rootGetters.selectedTemplate.covers
      });
    },
    swapPolicyCover({ commit }, payload) {
      commit("swapPolicyCover", payload);
    },
    updatePolicyValue({ state, commit }, {countryId, type, coverId, value}) {
      //countryId, coverId, type, value) {
      let localPolicies = JSON.parse(JSON.stringify(state.localPolicies));
      if (countryId === "all") {
        if (type === "inc") {
          localPolicies.forEach(country => {
            country.covers[coverId].included = parseInt(value);
          });
        } else if (type.startsWith("cover-limit")) {
          const changeType = type.replace("cover-limit-", "");
          localPolicies.forEach(country => {
            country.covers[coverId][changeType] = parseFloat(
              value
            );
          });
        } else {
          localPolicies.forEach(country => {
            if (Object.prototype.hasOwnProperty.call(country, type)) {
              country[type] = value;
            }
          });
        }
      } else {
        const country = localPolicies.find(
          country => country.options.country.id === countryId
        );
        if (country) {
          if (type === "inc") {
            country.covers[coverId].included = parseInt(value);
          } else if (type === "extra") {
            country.showExtra = value;
          } else if (type.startsWith("cover-limit")) {
            const changeType = type.replace("cover-limit-", "");
            if (
              Object.prototype.hasOwnProperty.call(
                country.covers,
                coverId
              )
            ) {
              country.covers[coverId][changeType] = value;
            }
          } else if (
            Object.prototype.hasOwnProperty.call(country, type)
          ) {
            country[type] = value;
          }
        }
      }

      commit("setLocalPolicies", { localPolicies: localPolicies });
    },
    updatePolicyDataFromCoverage({ commit, rootGetters }, payload) {
      let fields = [
        "occurrenceLimit",
        "aggregateLimit",
        "maxDeductible",
        "minDeductible",
        "percentageDeductible",
        "included",
        "allowInclude",
        "allowExclude"
      ];
      let covers = rootGetters.selectedTemplate.covers;
      if (payload.field !== "all") {
        fields = [payload.field];
      }
      if (payload.coverId !== "all") {
        covers = [
          rootGetters.selectedTemplate.covers.find(
            cover => cover.id === payload.coverId
          )
        ];
      }
      commit("updatePolicyCoverageFromTemplate", {
        covers: covers,
        fields: fields
      });
    },
    changePolicyLimit({ commit }, payload) {
      commit("setPolicyLimit", payload);
    },
    setPolicySelectedWikiItem({ commit }, { countryId, wikiItem }) {
      commit("setPolicySelectedWikiItem", { countryId, wikiItem });
    },
  },
  getters: {
    localPoliciesStateForStorage: state => {
      return {
        programmeStateId: state.programmeStateId,
        name: state.name,
        localPolicies: state.localPolicies,
        localContactDetails: state.localContactDetails,
        policyLimit: state.policyLimit,
        emailAddress: state.emailAddress,
        partnerName: state.partnerName,
        clientName: state.clientName,
        clientRef: state.clientRef,
        underwriterName: state.underwriterName,
        brokerName: state.brokerName,
        referenceNumber: state.referenceNumber,
        inceptionDate: state.inceptionDate,
        expiryDate: state.expiryDate,
        dateOfRate: state.dateOfRate,
        completionStatus: state.completionStatus,
        mpolPremium: state.mpolPremium
      };
    },
    localPolicies: state => state.localPolicies,
    localContactDetails: state => state.localContactDetails,
    getPolicyLimit: state => state.policyLimit,
    getPolicyDeductible: state => state.overallPolicyDeductible,
    emailAddress: state => state.emailAddress,
    partnerName: state => state.partnerName,
    clientName: state => state.clientName,
    clientRef: state => state.clientRef,
    underwriterName: state => state.underwriterName,
    brokerName: state => state.brokerName,
    referenceNumber: state => state.referenceNumber,
    inceptionDate: state => state.inceptionDate,
    programmeInstructionsActive: state =>
      !(
        state.name === "" ||
        state.emailAddress === "" ||
        state.clientName === "" ||
        (FeaturesMixin.methods.usingAMENAElements() ? state.clientRef === "" : false)
      ),
    programmeStateId: state => state.programmeStateId,
    programmeName: state => state.name,
    submitted: state => state.completionStatus !== "prospect",
    completionStatus: state => state.completionStatus,
    dateOfCurrencyRate: state => state.dateOfRate,
    dateOfRateUnavailable: state => {
      const dateUnavailable = new Date(state.dateUnavailable);
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ][dateUnavailable.getMonth()];
      return (
        dateUnavailable.getDate() +
        " " +
        month +
        " " +
        dateUnavailable.getFullYear()
      );
    },
    policyCountries: state => {
      return state.localPolicies.map(policy => policy.options.country.value);
    },
    mpolPremium: state => state.mpolPremium,
    hasErrors: state => state.hasErrors
  }
};
