<template>
  <div class="capabilities-checker" id="capabilities-checker">
    <div class="row">
      <div class="col">
        <h5 :is="printStyles ? 'h4' : 'h5'" :class="printStyles ? 'd-inline-flex ml-3' : 'd-flex my-3'">Capabilities Check</h5>
        <scaled-image class="float-right" :class="{'d-none': !printStyles}" :src="clientImgSrc('site-topbar-rightv2.png')" :height="50"/>
        <scaled-image class="float-left" style="background-color: #191D64;" :class="{'d-none': !printStyles}" :src="clientImgSrc('side-bar-logo.png')" :height="50"/>
        <br v-if="printStyles" /><br v-if="printStyles" />
        <div class="panel-body mb-2">
          <transition name="fade">
            <div v-if="$store.getters.isLoadingWikiData" class="my-4">
              <span id="imgLoading">
                <i class="fa fa-sync fa-spin"></i>&nbsp; Getting capabilities data...
              </span>
            </div>
            <div v-else-if="$store.getters.localPolicies.length === 0" class="py-3">
              <div class="wiki-data-not-found">
                <strong><i class="fa fa-info-circle"></i>&nbsp;No wiki data found for the selected countries</strong>
              </div>
            </div>
            <div v-else class="mt-3">
              <div class="wiki-data-not-found">
                <strong v-if="countriesMissingWikiData.length > 0">
                  <i class="fa fa-info-circle"></i>&nbsp;No wiki data found for countries: {{ countriesMissingWikiData }}
                </strong>
              </div>
              <div v-if="printStyles" class="alert alert-primary">
                <div class="row">
                  <div class="col-6">
										<div class="row">
											<div class="col-12">
												<strong>Class of Business: </strong>
												<em>{{ classType }}</em>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<strong>Policy Type: </strong>
												<em>{{ policyClass }}</em>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<strong>Programme Name: </strong>
												<em>{{ $store.getters.programmeName }}</em>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<strong>Inception Date: </strong>
												<em>{{ inceptionDate }}</em>
											</div>
										</div>
                  </div>
                  <div class="col-6">
										<div class="row">
											<div class="col-12">
												<strong>Countries Selected: </strong>
												<em>{{ $store.getters.getCountryList }}</em>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<strong>Client (Account Name): </strong>
												<em>{{ $store.getters.clientName }}</em>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<strong>Client ID/Reference: </strong>
												<em>{{ $store.getters.clientRef }}</em>
											</div>
										</div>
                  </div>
                </div>
              </div>
              <!-- TODO: Configure this table in an array and pass to a component to programmatically render
                          We need to remove the logic from this component before it becomes more spaghettified -->
              <div class="table-container" ref="capabilitiesTableWrapper" :class="{'print-styles': printStyles}">
								<table class="table table-bordered table-hover sticky-header" ref="capabilitiesTable" id="capabilitiesTable" :class="{'print-styles': printStyles}">
                  <thead>
                    <tr class="footable--group-row" v-if="hasExtraHeaderRow()">
                      <th data-group="group1"></th>
                      <th
                        data-group="group2"
                        :colspan="usingFinc() ? 4 : 3"
                        class="border-bottom text-center"
												:style="printStyles ? 'padding-right:0;' : ''"
                      >
                        Coverage Types
                      </th>
                      <th
                        data-group="group3"
                        :colspan="localRequirementsColumns().length"
                        class="border-bottom text-center"
												:style="printStyles ? 'padding-right:0' : ''"
                      >
                        Local Requirements
                      </th>
                      <th data-group="group4"
                          :colspan="minimumRequirementsColumns().length"
                          data-breakpoints="ty xs sm md"
                          class="border-bottom"
                          v-if="usingMinimumRequirements()"
													:style="printStyles ? 'padding-right:0' : ''"
                          >
                        Policy Requirements
                      </th>
                      <th data-group="group41"
                          data-breakpoints="ty xs sm md"
                          :colspan="jurisdictionColumns().length"
                          class="border-bottom"
                          v-if="jurisdictionColumns().length > 0"
													:style="printStyles ? 'padding-right:0' : ''"
                      >
                        Jurisdiction Index Rating
                      </th>
                      <th data-group="group5" colspan="2" class="border-right" v-if="usingMaxPolicyLimit()" :style="printStyles ? 'padding-right:0' : ''"></th>
											<th data-group="group7" v-if="usingCountryAlerts() && !printStyles">Alerts</th>
                    </tr>
                    <tr>
                      <th data-group="group1">Country</th>
                      <th data-group="group2">
												<div :class="!printStyles ? 'caps-heading' : ''">{{ getLocalPolicyLabel() }}</div>
											</th>
                      <th data-group="group2"
                          v-if="usingFOSNonAdm()">
												<div :class="!printStyles ? 'caps-heading' : ''">Freedom of Service</div>
											</th>
                      <th data-group="group2"
                        	data-breakpoints="ty xs sm"
                        	v-if="usingFOSNonAdm()">
													<div :class="!printStyles ? 'caps-heading' : ''">Non-Admitted </div>
											</th>
											<th data-group="group2"
												v-if="usingFinc()">
												<div :class="!printStyles ? 'caps-heading' : ''">Finc</div>
											</th>
                      <th v-for="(col, index) in localRequirementsColumns()"
                          data-group="group4"
                          data-breakpoints="ty xs sm md"
                          :key="col.heading">
                        <div :class="!printStyles ? 'caps-heading' : ''">{{ col.heading }}</div>
                      </th>
                      <th v-for="(col, index) in minimumRequirementsColumns()"
                          data-group="group41"
                          data-breakpoints="ty xs sm md"
                          :key="col.heading">
                        <div :class="!printStyles ? 'caps-heading' : ''">{{ col.heading }}</div>
                      </th>
                      <template v-if="jurisdictionColumns().length > 0">
                        <th v-for="(col, index) in jurisdictionColumns()"
                            data-group="group45"
                            data-breakpoints="ty xs sm md"
                            :key="col.heading">
													<div :class="!printStyles ? 'caps-heading' : ''">{{ col.heading }}</div>
                        </th>
                      </template>
                      <th data-group="group5" data-breakpoints="ty xs" v-if="usingPricingMatrix()">
                        <div :class="!printStyles ? 'caps-heading' : ''">Indicative Premium ({{ $store.getters.requestedCurrencyCode }})</div>
                      </th>
                      <th data-group="group6" v-if="usingMaxPolicyLimit() && !usingMinimumRequirements()" class="border-left">
                        <div :class="!printStyles ? 'caps-heading' : ''">Policy Currency</div>
                      </th>
                      <th data-group="group6" v-if="usingMaxPolicyLimit()">
                        <div :class="!printStyles ? 'caps-heading' : ''">Maximum Local Policy Limit ({{ $store.getters.requestedCurrencyCode }})</div>
                      </th>
											<th data-group="group7" v-if="usingCountryAlerts() && !printStyles"><div style="width: 600px">Country Alerts</div></th>
										</tr>
                  </thead>
                  <tbody id="tblCapabilities" v-for="country in $store.getters.localPolicies">
                    <template>
                        <!-- TODO: Move the printStyles state to a more accessible place s.t. we don't have to pass it to any component that needs to react to it -->
                      <tr :key="country.options.country?.value + 'a'" :class="{'doubleCapabilitiesRow': usingCountryPartnerInput()}">
                        <wiki-table-data
                          :wikiData="country.options.country"
                          :centered="false"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                          :wikiData="country.options.localPolicy"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                            v-if="usingFOSNonAdm()"
                            :wikiData="country.options.fos"
                            :countryId="country.options.country.country_id"
                            :print-styles="printStyles"
                        />
                        <wiki-table-data
                          v-if="usingFOSNonAdm()"
                          :wikiData="country.options.nonAdmitted"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
												<wiki-table-data
													v-if="usingFinc()"
													:wikiData="country.options.finc"
													:countryId="country.options.country.country_id"
													:print-styles="printStyles"
												/>
                        <wiki-table-data v-for="col in localRequirementsColumns(country)"
                           :wikiData="col.data"
                           :key="col.heading"
                           :countryId="country.options.country.country_id"
													 :feature-type="col.heading"
                           :print-styles="printStyles"
                        />
                        <wiki-table-data v-for="col in minimumRequirementsColumns(country)"
                          :wikiData="col.data"
                          :key="col.heading"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data
                            v-if="usingPricingMatrix()"
                            :wikiData="{
                            value: (() => {
                              if (country.options.quoteRequired && country.options.quoteRequired?.value === 'Yes') {
                                return 'Quote Required';
                              } else if (country.options.indicativePremium && country.options.indicativePremium.premium_to) {
                                return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, 6)
                                  + ' - '
                                  + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, 6)
                              } else {
                                return '';
                              }
                              })(),
                            additional: (() => {
                              if (country.options.quoteRequired
                                && country.options.quoteRequired?.value === 'Yes'
                                && country.options.indicativePremium
                                && country.options.indicativePremium.premium_to
                                ) {
                                let sigFigFrom = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_from) + 2;
                                let sigFigTo = $options.filters.numsBeforeDecimal(country.options.indicativePremium.premium_to) + 2;
                                return $options.filters.formatCurrency(country.options.indicativePremium.premium_from, sigFigFrom)
                                    + ' - '
                                    + $options.filters.formatCurrency(country.options.indicativePremium.premium_to, sigFigTo);
                              } else {
                                return '';
                              }
                              })(),
                            level: country.options.indicativePremium ? country.options.indicativePremium.level : 'info'
                          }"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                        />
                        <wiki-table-data v-if="usingMaxPolicyLimit() && !usingMinimumRequirements()" 
                          :wikiData="country.options.policyCurrency"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                          />
                        <wiki-table-data v-if="usingMaxPolicyLimit()"
                          :wikiData="indicativeLimits(country)"
                          :countryId="country.options.country.country_id"
                          :print-styles="printStyles"
                          />
                        <wiki-table-data v-for="col in jurisdictionColumns(country)"
                             :wikiData="col.data"
                             :key="col.heading"
                             :countryId="country.options.country.country_id"
                             :print-styles="printStyles"
                        />
												<td v-if="usingCountryAlerts() && !printStyles">
													<span >{{ getCountryAlert(country) }}</span>
												</td>
                      </tr>
                      <tr v-if="usingCountryPartnerInput()" :key="country.options.country?.value + 'b'" class="doubleCapabilitiesRow">
                        <td :colspan="!printStyles ? '10' : '100'">
                          <div class="row">
                            <div class="col-3">
                              <label>
                                Local Fronting Carrier / Network Partner Legal Name
                              </label>
                              <br />
                              <template v-if="!networkPartnerAvailable(country)">
                                <span v-if="isAviva" class="text-danger">Please contact the Multinational Centre of Excellence if local placement is required.</span>
                                <span v-else class="text-danger">Network Partner has not been determined therefore please allow for extra time.</span>
                                <hr v-if="countrySanctioned(country)" />
                              </template>
                              <span v-if="countrySanctioned(country)" class="text-danger">{{ countrySanctionedText(country) }}</span>
                              <span v-if="countryAvailable(country) && !countrySanctioned(country)">{{ country.options.company.name }}</span>
                            </div>
                            <div class="col-3" v-if="!isAviva">
                              <label>
                                Affiliation
                              </label>
                              <br />
                              <span>{{ country.options.affiliation?.value }}</span>
                            </div>
                            <div class="col-4" v-if="selectedWikiItem(country.options.country.country_id)">
                              <div class="wiki-additional"
                                   :class="'capabilities-' + selectedWikiItem(country.options.country.country_id).level"
                                   style="width: 100%; height: 100%;padding: 4px;"
                                >
                                <label class="text-dark">
                                  <span v-if="selectedWikiItemName(country.options.country.country_id)">{{ selectedWikiItemName(country.options.country.country_id) }} - </span>Additional Commentary:
                                </label>
                                <br />
                                <em>{{ selectedWikiItemAdditional(country.options.country.country_id) }}</em>
                              </div>
                            </div>
                          </div>
													<div class="row" v-if="usingCountryAlerts() && printStyles">
														<div class="col-10">
																<span class="text-wrap">{{ getCountryAlert(country) }}</span>
														</div>
													</div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
							</div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="row" v-if="!usingSingleDetailsPane()">
      <div class="col" id="capabilitiesUserData">
        <h5 v-if="!inquiryOnlyMode() && !printStyles">Save Report or Progress Local Placement</h5>
        <p v-if="!inquiryOnlyMode() && !printStyles">Saving the report enables you to retrieve it at a later date</p>
        <p v-if="!inquiryOnlyMode() && !printStyles">
          Instructing placement will alert your local Network Co-ordinator to
          commence the local policy quotes (where required)
        </p>
        <div v-for="(fieldConfig, field) in activeFields" :key="field">
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.TEXT || fieldConfig.type==inputTypes.EMAIL"
            >
            <!-- TODO: Add printStyles versions of these inputs - just use hidden <em>s? -->
            <label :for="field" class="col-sm-2 col-form-label" :class="{'d-none': printStyles}">
              {{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong>
            </label>
            <div class="col-sm-4">
              <input
                :id="field"
                :type="fieldConfig.type"
                :placeholder="'Insert ' + fieldConfig.label"
                autocomplete="off"
                :value="$store.getters[field]"
                @input="handleInput($event.target?.value, field)"
                class="form-control"
                :class="{'d-none': printStyles}"
              />
            </div>
          </div>
          <div class="form-group row" v-if="fieldConfig.type==inputTypes.DATE">
            <label :for="field" class="col-sm-2 col-form-label" :class="{'d-none': printStyles}"
              >{{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label
            >
            <div class="col-sm-4">
              <date-picker
                :id="field"
                :value="inceptionDate"
                placeholder="fieldConfig.label"
                :monday-first="true"
                format="dd MMM yyyy"
                @input="value => sendInput(value, field)"
                input-class="date-picker-input"
                :class="{'d-none': printStyles}"
              ></date-picker>
            </div>
          </div>
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.PARTNER && usingPartnerDropdown()"
            >
            <label for="partnerName" class="col-sm-2 col-form-label" :class="{'d-none': printStyles}"
              >Partner Name<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label>
            <div class="col-sm-4">
              <v-select
                  id="partnerName"
                  placeholder="Select Partner Name"
                  @input="value => sendInput(value, 'partnerName')"
                  @search="getPartnersList"
                  :options="partnersList"
                  :clearable="false"
                  :class="{'d-none': printStyles}"
                  >
              </v-select>
            </div>
          </div>
          <div class="form-group row"
            v-if="fieldConfig.type==inputTypes.CLIENT && usingClientAutoComplete()"
            >
            <label for="clientName" class="col-sm-2 col-form-label" :class="{'d-none': printStyles}"
              >{{ fieldConfig.label }}<strong v-if="fieldConfig.required" :class="{'d-none': printStyles}">&nbsp;*</strong></label>
            <div class="col-sm-4">
              <input
                  id="clientName"
                  placeholder="Client (Account) Name"
                  @input="handleInput($event.target?.value, 'clientName')"
                  :value="$store.getters.clientName"
                  class="form-control"
                  :class="{'d-none': printStyles}"
              />
            </div>
            <div class="col-sm-4" v-if="usingClientAutoComplete">
              <sounds-like :current="$store.getters.clientName" 
                           :list="clientsList" 
                           itemKey="client_id"
                           itemText="client_name"
                           itemLabel="Clients"
                           @select="value => handleInput(value, 'clientName')"/>
            </div>
          </div>
        </div>
        <small class="text-muted" :class="{'d-none': printStyles}"><em>* Required data</em></small>

      </div>
    </div>

    <b-alert show variant="info" v-if="shouldShowCapabilitiesBanner()">
      <i class="fa fa-info-circle fa-2x"></i>&nbsp;The information provided herein is preliminary, subject to change and not binding. 
      It is provided to aid in understanding basic information about the countries involved and should not be relied
      on as representations or warranties of any kind.
      <small :class="{'d-none': !printStyles}"><em>Data accurate as of {{ todayFormatted }}</em></small>
    </b-alert>
    <inquiry-actions v-if="!printStyles" :swapSaveAndSlot="capabilitiesPDFExportEnabled() && inquiryOnlyMode() && canSaveReport()">
      <button v-if="!inquiryOnlyMode()"
        class="btn btn-success float-right ml-2"
        :disabled="saving || !$store.getters.programmeInstructionsActive || $store.getters.localPolicies.length === 0"
        @click="goToProgrammeInstructions"
      >
        <i class="fa fa-paper-plane"></i>&nbsp; {{ getInstructCoordinatorLabel() }}
      </button>
      <button v-if="capabilitiesPDFExportEnabled()"
        class="btn btn-success"
        :class="{'float-right': !inquiryOnlyMode() || !canSaveReport()}"
        @click="() => {this.generatingPDF = true; exportCapabilities()}"
      >
        <span :class="{'d-none': !generatingPDF}">
          <i class="fa fa-sync fa-spin"></i>
        </span>
        <span :class="{'d-none': generatingPDF}">
          <i class="fa fa-download"></i>
        </span>
        &nbsp;Create PDF Output
      </button>
    </inquiry-actions>
    <login-modal
      v-if="!$store.getters.isLoggedIn"
      :email="$store.getters.emailAddress"
      subtitle="Please login before you save"
      login-button-text="Login and Save"
      @logged-in="saveReport"
    ></login-modal>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import WikiTableData from "./WikiTableData";
import WikiAdditionalComments from "./WikiAdditionalComments";
import TooltipButton from "./TooltipButton";
import LoginModal from "./LoginModal";
import FeaturesMixin, { inputTypes } from "@/mixins/features.mixin";
import SanctionsMixin from "@/mixins/sanctions.mixin";
import InquiryActions from "./InquiryActions";
import ScaledImage from "./ScaledImage";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import html2pdf from "html2pdf.js";
import SoundsLike from "./SoundsLike";

export default {
  name: "CapabilitiesChecker",
  props: {
    capabilitiesData: Array,
    capabilitiesActive: Boolean
  },
  components: {
    WikiTableData,
    TooltipButton,
    LoginModal,
    InquiryActions,
    ScaledImage,
    WikiAdditionalComments,
    SoundsLike
  },
  mixins: [FeaturesMixin, SanctionsMixin],
  computed: {
    inceptionDate() {
      let inDate = this.$store.getters.inceptionDate;
      if (!inDate) {
        return "";
      }
      inDate = new Date(inDate);
      return new Date(inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000)
        .toISOString()
        .split("T")[0];
    },
    countriesMissingWikiData() {
      let localPolicies = this.$store.getters.localPolicies;
      return this.$store.getters.getSelectedCountries.filter(
        country => {
          return !localPolicies.find(
            localPolicy =>
              localPolicy.options.country.country_id === country.country_id
          );
        }
      ).map(country => country.country_name)
      .join(', ');
    },
    activeFields() {
      return this.activeCapabilitiesFields();
    },
    classType() {
      return this.$store.getters.classType ? this.$store.getters.classType.policy_class_type_caption : '';
    },
    policyClass() {
      return this.$store.getters.policyClass ? this.$store.getters.policyClass.policy_class_caption : '';
    },
    todayFormatted() {
      const options = {
        day: 'numeric', 
        month: 'long', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit'
      };
      return new Date().toLocaleString("en-GB", options);
    },
    isAviva() {
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
    }
  },
  data: () => {
    return {
      partnersList: [],
      clientsList: [],
      saving: false,
      devMode: process.env.NODE_ENV !== "production",
      inputTypes,
      showPDFElements: false,
      generatingPDF: false,
      printStyles: false,
			tableWidth: 0,
			tableHeight: 0,
    };
  },
  methods: {
		// TODO: Refactor me - This makes the input boxes feel really sluggish, as it's saving to the Vuex store with every character input
    sendInput(value, type) {
      const payload = {};
      payload[type] = value;

      // If we're changing the inception date here, reset the expiry date to null so that a default is set in the next step.
      if(type === 'inceptionDate') {
        payload['expiryDate'] = null;
      }

      this.$store.dispatch("setPolicyDetail", payload);
    },
    async getPartnersList() {
      const url = process.env.VUE_APP_API_ENDPOINT + "/partner";

      try {
        let { data } = await axios.get(url, {
          withCredentials: true
        });
        this.partnersList = [...new Set(data.map(function(value) {
          return value.partner_name;
        }))];
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async getClientsList(force = false) {
      if (this.clientsList.length > 0 && !force) {
        return;
      }
      try {
        let { data } = await ApiService.get('client');
        this.clientsList = data;
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
      }
    },
    async goToProgrammeInstructions() {
      if (this.underwritingPortalMode()) {
        await this.$store.dispatch("storeState", { resetProgrammeAfterSave: false });
      }
      await this.$router.push({ name: "Programme Instructions" });
    },
    setProgrammeName(value) {
      this.$store.commit("setProgrammeName", {name: value});
    },
    handleInput(value, inputType) {
      if (inputType === "programmeName") {
        this.setProgrammeName(value);
      } else {
        this.sendInput(value, inputType);
      }
    },
    handleCountryInput(countryId, field, value) {
      this.$store.dispatch(
        'updatePolicyValue',
        {
          countryId,
          coverId: '',
          type: field,
          value
        }
      );
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Inquiries" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
    async exportCapabilities() {
      this.generatingPDF = true;

			const capabilitiesTableWrapper = this.$refs.capabilitiesTableWrapper;
			capabilitiesTableWrapper.style.maxHeight = '';

      let opt = {
        margin: 3,
        image: {type: "jpeg", quality: 0.98},
        filename: "iCede Underwriting Portal - Inquiry Export", // Add Date to filename
        html2canvas: {scale: 2.},
        pagebreak: { avoid: ["tr", ".alert", "div.row", "tbody"] },
        jsPDF: { orientation: "L" },
      };
      let element = document.getElementById('capabilities-checker');
      const context = this;
      this.printStyles = true;
      html2pdf().set(opt).from(element).save().then(() => {
				context.generatingPDF = false;
				context.printStyles = false;
				capabilitiesTableWrapper.style.maxHeight = '600px';
			});
    },
    localRequirementsColumns(country){
      let columns = [
        {heading: "Broker Required", data: country?.options?.brokerReq},
      ];
      if (this.usingCentralColletion()) {
        columns.push({"heading":"Premium Collection Methods", "data": country?.options?.cetColl});
      }
      columns.push({"heading":"Cash Before Cover", "data": country?.options?.cashBef});

      if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
        columns.push({"heading":"Tariff Rated", "data": country?.options?.tariffRating});
        columns.push({"heading":"Filed Rating", "data": country?.options?.minFiledRate});
        columns.push({"heading":"Premium Currency", "data": country?.options?.premiumCurrency});
        columns.push({"heading":"Insured Payable Tax %", "data": country?.options?.totalAddTax});
        columns.push({"heading":"Insurer Payable Tax %", "data": country?.options?.totalDedTax});
        columns.push({"heading":"Local Pool Charges", "data": country?.options?.localPoolCoverage});
        columns.push({"heading":"Network Partner Override %", "data": country?.options?.networkPartnerPc});
        columns.push({"heading":"Network Partner Override Min", "data":  country?.options?.networkPartnerMin});
      }
      columns.push({"heading":"Local Retention", "data": country?.options?.localRetention});
      columns.push({"heading":"Local Reinsurance", "data": country?.options?.localReinsurance});

      return columns;
    },
    minimumRequirementsColumns(country) {
        if (!this.usingMinimumRequirements()) {
            return [];
        }

        if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_SOMPO)) {
          return [
            {heading: "Pre-Issue Documents Required (KYC, AML, Other)", data: country?.options?.preIssueDocuments},
            {heading: "Policy Currency", data: country?.options.policyCurrency},
            {heading: "Tariff or Local Rating Req'd", data: country?.options.tariffRatingApply},
            {heading: "Minimum Policy Premium (USD)", data: country?.options.minPremium,},
          ];
        } else if (this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
          return [
            {heading: "Manuscript Policy Permitted", data: country?.options?.manuscriptFlag,},
            {heading: "Maximum Policy Limit (GBP)", data: country?.options.maxLimit,},
            {heading: "Tacit Renewal", data: country?.options?.tacitRenew,},
          ];
        }
        return [
            {heading: "", data: country?.options.enforceBefore},
            {
              heading: "Required Instructions",
              data: {
                value: country?.options.minimumRequirements?.value.join(', '),
                level: country?.options.minimumRequirements.level
              }
            },
            {heading: "Policy Currency", data: country?.options.policyCurrency},
        ];
    },
    jurisdictionColumns(country) {
			// 08.05 - temporary disable the jurisdictionColumns as they are not being used
      if (!this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA)) {
        return [
          { heading: "JI Rating - Direct", data: country?.options?.jiratingDI},
          { heading: "JI Rating - Reinsurance", data: country?.options?.jiratingReins},
        ];
      }
      return [];
    },
    networkPartnerAvailable(country) {
      return (!country.options.affiliation || country.options.affiliation.available);
    },
    countryAvailable(country) {
      return !this.countrySanctioned(country) && this.networkPartnerAvailable(country);
    },
    countrySanctioned(country) {
      return country.options.country.sanctionSeverity != 0;
    },
    countrySanctionedText(country) {
      return this.getSanctionMessage(country.options.country.sanctionSeverity);
    },
    selectedWikiItem(countryId) {
      const localPolicy = this.$store.getters.localPolicies.find(policy => policy.options.country.country_id == countryId);
      if (!localPolicy) {
        return null;
      }
      return localPolicy.selectedWikiItem;
    },
    selectedWikiItemName(countryId) {
      const item = this.selectedWikiItem(countryId);
      if (!item || !item.feature_name) {
        return '';
      }
      return item.feature_name;
    },
    selectedWikiItemAdditional(countryId) {
      const item = this.selectedWikiItem(countryId);
      if (!item || !item?.additional) {
        return '';
      }
      return item?.additional;
    },
    indicativeLimits(country) {
      return country.options.indicativeLimits ?? {level: "success", value: "", id: "indicativeLimits" + country.options.country.country_id};
    },
    usingCountryAlerts() {
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
    },
    getCountryAlert(country){
      let wikiMcoeNotes = country.options?.wikiMcoeNotes?.value;
      if (typeof(wikiMcoeNotes) == 'string') {
        return wikiMcoeNotes;
      } else {
        return "";
      }
    },
		capabilitiesTableSetup() {
			const capabilitiesTable = this.$refs.capabilitiesTable;
			const capabilitiesTableWrapper = this.$refs.capabilitiesTableWrapper;

			if(capabilitiesTable && !this.printStyles) {
				const tableWidth = capabilitiesTable.offsetWidth;
				const tableHeight = capabilitiesTable.offsetHeight;

				capabilitiesTableWrapper.style.height = capabilitiesTableWrapper.offsetHeight + 'px';
				capabilitiesTableWrapper.style.maxWidth = '';

				capabilitiesTable.style.display = 'none';
				const maxWidth = capabilitiesTableWrapper.offsetWidth;
				capabilitiesTableWrapper.style.maxWidth = maxWidth + 'px';
				capabilitiesTableWrapper.style.height = '';

				capabilitiesTableWrapper.style.overflowX = tableWidth > maxWidth ? 'auto' : 'visible';
				capabilitiesTable.style.display = '';

				let maxHeight = '550px';

				capabilitiesTableWrapper.style.maxHeight = maxHeight;
				capabilitiesTableWrapper.style.overflowY = tableHeight > maxHeight ? 'auto' : 'visible';
			}
		},
  },
	mounted() {
    // Redirect to the country selection tab if we don't have the data for this tab
    if (!this.capabilitiesActive && !this.$store.getters.isLoadingWikiData) {
      this.$router.replace({ name: "Country Select" });
    }

    if (this.usingAMENAElements()) {
      this.getPartnersList();
    }

    if (this.usingClientAutoComplete()) {
      this.getClientsList(true);
    }

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

    document.getElementById("myTab").scrollIntoView({ behavior: "smooth" });

		this.capabilitiesTableSetup();
		window.addEventListener('resize', this.capabilitiesTableSetup);
  }
};
</script>
<style scoped>
.capabilities-success {
  background-color: #52de73 !important;
}
.capabilities-danger {
  background-color: #ed616f !important;
  font-weight: bold;
}
.capabilities-warning {
  background-color: #fcc930 !important;
}
.caps-heading {
  width: 100px;
}
.table.b-table {
  height: 1200px;
}
div.hide {
  display: none;
}
.wiki-data-not-found {
  font-size: 14pt;
  margin-bottom: 14px;
}
input.form-control.primary-office {
  user-select: none;
  width: 600px;
  background-color: #FFFFFF;
}
/* Striped rows when using two `tr`s for each country to keep the wiki data and partner input visually grouped */
.doubleCapabilitiesRow:nth-child(4n+1), .doubleCapabilitiesRow:nth-child(4n+2) {
  background-color: #F5F5F5;
}
.table-container.print-styles {
	width: 100%;
	min-width: 100%;
	display: grid;
	justify-content: space-between;
}
table.print-styles {
	width: 100%;
	word-break: break-all;
}
table.print-styles tr th {
  padding: 0 0 2px 2px;
  font-size: 0.6em;
	word-break: keep-all;
}
table.print-styles tr td {
  padding: 0 0 0 2px;
  font-size: 0.6em;
}
table.print-styles tr td label {
  font-size: 1em;
}
label.print-styles {
  font-size: 0.6em;
}
#capabilitiesUserData em {
  font-size: 0.8em;
}
.table-scroll {
  width: 100%;
  position: relative;
}
/* Make the table header sticky */
table.table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
table.table-responsive thead th {
  background-color: #FFFFFF;
}
table#capabilitiesTable th {
	box-shadow: inset 1px 0 0 #e1e1e1;
}
table#capabilitiesTable {
	margin:0;
	border:none;
	background-color:#FFF;
	border-collapse: separate;
	border-spacing: 0;
}
table#capabilitiesTable thead {
	background: #f1f1f1;
	color: #535353;
	position:sticky;
	top:0;
	z-index:555;
}
table#capabilitiesTable td {
	background:#fff;
	padding:4px 5px;
	border-left: 0;
	border-right: 0;
}
table#capabilitiesTable th:first-child {
	position:sticky;
	left:0;
	background: #f1f1f1;
	z-index:9999;
	border-right: 1px solid #e1e1e1;
}
table#capabilitiesTable .doubleCapabilitiesRow td:first-child {
	position: sticky;
	left:0;
	z-index: 2;
}
table#capabilitiesTable td:first-child {
	position:sticky;
	left:0;
	border-left: 0;
	box-shadow: inset 1px 0 0 #e1e1e1;
}
h3 {
  font-size: 1.3em;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
}
.card .row .col label {
  font-size: 0.7em;
}
.card .row .col small {
  font-size: 0.7em;
}
div.row.comments-row {
  border-bottom: 1px solid #e9ecef;
}
div.row.comments-row:last-of-type {
  border-bottom: none;
}
</style>
