import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import { countries } from "./modules/countries.js";
import { wiki } from "./modules/wiki.js";
import { localPolicies } from "./modules/localPolicies.js";
import { covers } from "./modules/covers.js";
import { user } from "./modules/user.js";
import { underwriting } from "./modules/underwriting";

import axios from "axios";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  modules: {
    countries,
    wiki,
    localPolicies,
    covers,
    user,
    underwriting
  },
  state: {
    appVersion: "0.1.0",
    submitting: false
  },
  mutations: {
    updateVersionNumber(state, { version }) {
      state.appVersion = version;
    },
    setSubmitting(state, { submitting }) {
      state.submitting = submitting;
    }
  },
  actions: {
    async storeState({ dispatch, commit, rootGetters }, payload) {
      if (
        payload === undefined ||
        payload?.resetProgrammeAfterSave === undefined
      ) {
        payload = {
          resetProgrammeAfterSave: true
        };
      }

      commit("setSubmitting", { submitting: true });
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress";

      let progName = "";
      if (rootGetters.programmeName !== "") {
        progName += ' - "' + rootGetters.programmeName + '"';
      }

      const state = {
        countries: rootGetters.countryStateForStorage,
        covers: rootGetters.coverStateForStorage,
        localPolicies: rootGetters.localPoliciesStateForStorage,
        wiki: rootGetters.wikiStateForStorage,
        lastUrlName: rootGetters.lastUrlName,
        producing_office_id: rootGetters.producing_office_id
      };

      try {
        let { data } = await axios.post(
          url,
          {
            stateId: rootGetters.programmeStateId,
            state: state
          },
          {
            withCredentials: true
          }
        );
        // Successfully saved the Inquiry state
        if (data.success) {
          // Show success toast to user
          Vue.toasted.success(
            "Successfully Saved Programme Inquiry" + progName
          );

          commit("setProgrammeStateId", { id: data.inquiry_tool_progress_id });

          if (payload.resetProgrammeAfterSave) {
            commit("resetProgramme");
            dispatch("selectDeselectCountry", {
              countryId: "all",
              checked: false
            });
          }
        } else {
          throw "Server returned false when saving Programme Inquiry";
        }
      } catch (err) {
        // Show error toast to user
        Vue.toasted.error(
          "An error occurred while saving Programme Inquiry" +
            progName +
            ", please try again."
        );
        console.error(err);
      }
      commit("setSubmitting", { submitting: false });
    },
    async loadState({ dispatch, commit }, payload) {
      // this.replaceState(payload.state);

      // Restore the state using actions and mutations - this way it can be tracked and reversed in dev tools
      await dispatch("loadCountryStateFromStorage", payload.state);
      dispatch("loadCoverStateFromStorage", payload.state);
      dispatch("loadInquiryProgressDocumentTypes", payload.state);
      dispatch("loadInquiries", payload.state);
      commit("loadLocalPoliciesStateFromStorage", payload.state.localPolicies);
      commit("loadWikiStateFromStorage", payload.state.wiki);
      commit("setProgrammeStateId", { id: payload.stateId });
      commit("setPOID", { id: payload.state.producing_office_id });
    },
    startNewInquiry({ dispatch, commit }) {
      // Wipe all customised data and reset programme state ID
      // Deselect all countries
      dispatch("selectDeselectCountry", { countryId: "all", checked: false });
      dispatch("changePolicyClass", { policyClass: null });
      dispatch("changeClassOfBusiness", { classType: null });
      commit("resetProgramme");
      commit("resetProducingOffice");
    },
    versionChanged({ state, commit }) {
      return new Promise((resolve, _) => {
        if (process.env.VUE_APP_VERSION !== state.appVersion) {
          console.log(
            "Version updated from '%s' to '%s'",
            state.appVersion,
            process.env.VUE_APP_VERSION
          );

          commit("updateVersionNumber", {
            version: process.env.VUE_APP_VERSION
          });

          resolve(true);
        } else {
          console.log("Version is already '%s'", state.appVersion);
        }
        resolve(false);
      });
    }
  },
  getters: {
    submitting: state => state.submitting
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexLocal.plugin]
});
