import ApiService from "@/services/api.service";
import * as Sentry from "@sentry/vue";
import store from "@/store";
import { USER_TYPE } from "@common/src/constants";

const AuthService = {
    async login(email, password) {
        try {
            const response = await ApiService.post(
                'login',
                {email, password},
                true
            );
            return response?.data ?? undefined;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async loginWithToken(token) {
        try {
            let response = await ApiService.post(
                'loginWithToken',
                { token },
                true
            );
            return response?.data ?? undefined;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async logout() {
        try {
            return await ApiService.get('logout', true);
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async changePassword(currentPassword, newPassword) {
        try {
            let { data } = await ApiService.post(
                'changePassword',
                {currentPassword, newPassword},
                true
            );
            return data;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async setAccountPassword(userHash, newPassword) {
        try {
            // console.log("Sending set account password request", userHash, newPassword);
            let { data } = await ApiService.post(
                'setAccountPassword',
                {userHash: userHash, newPassword: newPassword},
                true
            );
            return data;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            return {
                success: false,
                errors: [
                    "We were not able to receive your password, please try again later. "
                    + "(This may be because your device is not connected to the internet)"
                ]
            };
        }
    },
    async sendOTP(method, mobile) {
        try {
            let { data }  = await ApiService.post(
                'twoFactor/sendCode',
                {method, mobile}
            );
            return data;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async authenticate(oneTimePassword) {
        try {
            let { data }  = await ApiService.post(
                'twoFactor/authenticate',
                {oneTimePassword}
            );
            return data;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async acceptTerms() {
        try {
            let { data }  = await ApiService.post(
                'twoFactor/acceptTerms',
            );
            return data;
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async ping() {
        try {
            return ApiService.get('ping');
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    },
    async validatePasswordResetCode(userId, code) {
        try {
            await ApiService.post(
                'validatePasswordResetCode',
                { 'user': userId, 'code': code }
            );
            return true;
        } catch(err) {
            console.error(err);
            Sentry.captureException(err);
            return false;
        }
    },
    async loginSSO() {
        try {
            let url = new URL(process.env.VUE_APP_INQUIRY_API_ENDPOINT + "/loginSSO/" + USER_TYPE.UNDERWRITER);
            let { data } = await ApiService.get(url);
            store.commit("setUser", { user: data.user, twoFactor: data.twoFactor });
            return data;
        } catch(err) {
            console.error(err);
            Sentry.captureException(err);
            return false;
        }
    },
}

export default AuthService;
