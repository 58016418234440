<template>
    <div id="Alerts">
        <div class="row" v-if="!this.communication">
            <div class="col-12">
                <card type="primary">
                    <div v-if="$store.getters.isLoggedIn" class="mt-3">
                        <div v-if="loadingAlerts">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                        <div v-else-if="alertsList.length === 0">
                          <p class="text-center">
                            There are no messages for this inquiry.
                          </p>
                        </div>
                        <div v-else>
                            <b-row>
                                <b-col sm="12" class="my-1">
                                    <b-table striped hover outlined :items="alertsList" :fields="fields"
                                             :current-page="currentPage"
                                             :per-page="perPage"
                                             :filter="filter"
                                             :filter-included-fields="filterOn"
                                             :sort-by.sync="sortBy"
                                             :sort-desc.sync="sortDesc">

                                        <template v-slot:cell(client_name)="row">
                                            <span v-if="row.item.programme">{{ row.item.programme.client.client_name }}</span>
                                            <span v-else>{{ row.item.inquiry_progress.progress_data.localPolicies.clientName }}</span>
                                        </template>

                                        <template v-slot:cell(producing_office)="row">
                                            <span v-if="row.item.programme">{{ row.item.programme.producing_company.regional_company_name }}</span>
                                            <span v-else>{{ row.item.inquiry_progress.producing_office.regional_company_name }}</span>
                                        </template>

                                        <template v-slot:cell(programme_name)="row">
                                            <span v-if="row.item.programme">{{ row.item.programme.master_policy_name }}</span>
                                            <span v-else>{{ row.item.inquiry_progress.progress_data.localPolicies.name }}</span>
                                        </template>

                                        <template v-slot:cell(button)="row">
                                            <b-button size="sm" @click="showAlert(row.item)">
                                                View
                                            </b-button>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-12">
                                    <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                            align="left"
                                            size="sm"
                                            class="my-0"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col">
                            <p>Log in to see your Alerts</p>
                            <login-modal @logged-in="reloadProgrammes"></login-modal>
                            <button class="btn btn-primary" v-b-modal.modal-login>Login</button>
                        </div>
                    </div>
                </card >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <communication-details v-if="this.communication" :communication="this.communication" @hideThread="hideThread()"></communication-details>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Card from "@/components/Cards/Card";
import LoginModal from "@/components/LoginModal";
import FeaturesMixin from "@/mixins/features.mixin";
import CommunicationDetails from "./CommunicationDetails.vue";
import InquiryActions from "@underwriters/src/components/InquiryActions";
export default {
    name: "Communication",
    components: {
        Card,
        LoginModal,
        CommunicationDetails,
        InquiryActions,
    },
    mixins: [FeaturesMixin],
    data: () => {
        return {
            message: "This is the message",
            loadingAlerts: true,
            loadingProgrammes: true,
            alertsList: [],
            communication: null,
            // Table settings
            fields: [
                { key: 'client_name', label: 'Client', sortable: true },
                { key: 'producing_office', label: 'Office', sortable: true },
                { key: 'programme_name', label: 'Programme', sortable: true },
                { key: 'master_policy_title', label: 'Subject', sortable: true },
                { key: 'date_created', label: 'Posted At', sortable: true },
                { key: 'posted_by_client_broker.full_name', label: 'Posted By', sortable: true },
                { key: 'button', label: '', sortable: false },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: 'date_created',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
          saving: false,
        };
    },
    props: {
        classTypeHasError: {
            type: Boolean,
            default: false,
        },
        classTypeError: {
            type: String,
            default: ""
        },
        producingOfficeHasError: {
            type: Boolean,
            default: false,
        },
        producingOfficeError: {
            type: String,
            default: ""
        },
    },
    methods: {
        async getAlerts() {
            this.loadingAlerts = true;

            try {
                let { data } = await axios.post(
                    process.env.VUE_APP_API_ENDPOINT + "/programme/communication",
                    {
                        inquiry_id: this.$store.getters.programmeStateId
                    },
                    {
                        withCredentials: true
                    }
                );

                if(data.alerts) {
                    this.alertsList = data.alerts;
                    this.totalRows = data.alerts.length;
                    this.loadingAlerts = false;
                }

            } catch (err) {
                console.error(err);
            }
        },
        showAlert(alert)
        {
            this.communication = alert;
        },
        hideThread()
        {
            this.communication = null;
        },
        changeProducingOffice(office) {
            if (office) {
                this.selectedProductionOffice = office;
                this.getAlerts();
            }
        },
    },
    mounted() {
        if (this.$store.getters.isLoggedIn) {
            this.getAlerts();
        }
    }
};


</script>