<template>
  <div>
    <h5 class="card-title">
      <template v-if="!editing">
        {{ this.editedLabel }}
        <span @click="toggleEdit">
          <i class="fa fa-edit"></i>
        </span>
      </template>
      <template v-else>
        <input v-model="editedLabel" @blur="saveEditedLabel" @keyup.enter="saveEditedLabel" />
        <b-button @click="toggleEdit" size="xs">Save</b-button>
      </template>
    </h5>
  </div>
</template>

<script>
import DocumentsService from "@underwriters/src/services/documents.service";

export default {
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editing: false,
      editedLabel: ""
    };
  },
  methods: {
    getFileLabel(fileVersions) {
      if (fileVersions !== undefined && fileVersions.length > 0) {
        let label = null;
        for (const fileVersion of fileVersions) {
          if (fileVersion.document_label !== undefined && fileVersion.document_label !== null) {
            label = fileVersion.document_label;
            break;
          }
        }

        if (label !== null) {
          return label;
        } else {
          return fileVersions[0].document.filename;
        }
      } else {
        return null;
      }
    },
    toggleEdit() {
      this.editing = !this.editing;
      if (this.editing) {
        this.editedLabel = this.getFileLabel(this.files);
      }
    },
    async saveEditedLabel() {
      for (let i = 0; i < this.files.length; i++) {
        this.$set(this.files[i], "document_label", this.editedLabel);
      }
      await this.$store.dispatch("updateDocumentLabels", { documents: this.files, label: this.editedLabel });
      await DocumentsService.updateDocumentLabel(this.files, this.editedLabel);
      this.$emit("file-label-updated");
      this.editedLabel = this.getFileLabel(this.files);
    }
  },
  mounted() {
    this.editedLabel = this.getFileLabel(this.files);
  }
};
</script>

<style scoped>
input {
  width: 70%;
}
</style>
