import store from "@/store";

// These should be in the order of precedence
const guards = [
    'shouldGoToLogin',
    'shouldGoToTerms',
    'shouldGoToTwoFactorAuth',
    'shouldGoToPasswordExpiry',
    //'shouldGoToClientSelect',
    'shouldGoToDashboard'
];

const RouteGuardService = {
    getNextRoute(to, from) {
        for (let guard of guards) {
            if (this[guard] === undefined) {
                continue;
            }
            let route = this[guard](to, from);
            if (route.length) {
                return route;
            }
        }
        return '';
    },
    shouldGoToLogin(to, from) {
        let route = '';
        if (!to.matched.some(record => record.meta.openRoute)
            && !store.getters.isLoggedIn
        ) {
            if (from.name === 'Login' || from.name === 'Register') {
                route = from.path;
            } else {
                route = '/login';
            }
        }
        return route;
    },
    shouldGoToTerms(to) {
        let route = '';
        if (to.name !== "Logout" && store.getters.isLoggedIn && store.getters.shouldGotoTerms) {
            route = '/terms';
        }
        return route;
    },
    shouldGoToTwoFactorAuth(to) {
        let route = '';
        if (to.name !== "Logout" && store.getters.isLoggedIn && store.getters.shouldGotoTwoFactor) {
            route = '/twoFactor';
        }
        return route;
    },
    shouldGoToPasswordExpiry(to) {
        let route = '';
        if (to.name !== "Logout"
            && store.getters.isLoggedIn
            && store.getters.passwordExpired
            && !store.getters.shouldGotoTwoFactor
        ) {
            route = '/passwordExpired';
        }
        return route;
    },
    shouldGoToClientSelect(to) {
        // let route = '';
        // if (store.getters.isLoggedIn
        //     && !store.getters.passwordExpired
        //     && to.matched.some(record => !record.meta.openRoute)
        //     && store.getters.selectedClientId === 0
        // ) {
        //     route = '/clientSelect';
        // }
        // return route;
    },
    shouldGoToDashboard(to) {
        let route = '';
        if (store.getters.isLoggedIn
            && !store.getters.passwordExpired
            && to.matched.some(record => record.meta.openRoute)
        ) {
            route = '/inquiry/countrySelect/1';
        }
        return route;
    }
}

export default RouteGuardService
