<template>
  <div class="country-selector">
    <div class="row">
      <div class="col">
        <h5 class="mt-2">Country Select</h5>
        <div class="panel-body mb-2">
          <div class="row">
            <div class="col-9">
              <div class="form-group">
                <label for="inputContinent">Select Continent</label>
                <v-select
                  :options="continents"
                  id="inputContinent"
                  :value="continent"
                  :clearable="false"
                  :reduce="continent => continent.name"
                  @input="changeContinent"
                >
                </v-select>
                <div v-if="$store.getters.loadingCountries" class="mt-4">
                  <span id="imgLoading">
                    <i class="fa fa-sync fa-spin"></i>&nbsp; Getting
                    countries...
                  </span>
                </div>
                <div v-else-if="!$store.getters.loadingCountries && filteredCountries.length === 0" class="mt-4">
                  <em><i class="fa fa-info-circle"></i>&nbsp;No countries found for the selected continent</em>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="pull-right">
                <button
                  id="selectAllCountries"
                  class="btn btn-primary btn-sm mt-4"
                  :class="{'hidden': !devMode}"
                  @click="checkAllCountries"
                >
                  <i class="fa fa-check-square"></i>&nbsp;Select All
                </button>
              </div>
            </div>
          </div>
					<div class="row">
						<div class="col-9">
							<input type="text" v-model="countryFilter" class="form-control mb-3" placeholder="Search by Country">
						</div>
					</div>
          <hr />
          <div class="row mt-3">
            <div class="col">
              <div
                v-if="filteredCountries.length > 0"
                class="row countriesContainer"
              >
                <div
                  v-for="country in filteredCountries"
                  :key="country.country_id"
                  class="col-12 col-md-6 col-lg-4"
                >
                  <div
                    class="form-group"
                    v-b-tooltip.hover.left="{
                      title: 'No Representation',
                      disabled: !country.noRepresentation,
                      variant: 'info'
                    }"
                  >
                    <input
                      class="form-control form-control-sm"
                      type="checkbox"
                      @change="checkCountry(country.country_id, $event)"
                      :id="country.iso_code_3"
                      :value="country.country_id"
                      :checked="country.checked"
                      :disabled="country.noRepresentation"
                    />
                    &nbsp;
                    <label
                      :for="country.iso_code_3"
                      :class="{ 'text-muted': country.noRepresentation }"
                      v-b-tooltip="sanctionTooltip(country.sanctionSeverity)"
                    >
                      {{ country.country_name }}
                      <span v-if="shouldShowSanctionMessage(country.sanctionSeverity)" 
                            class="badge badge-danger ml-1"
                            >
                        S
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inquiry-actions :show-save="false">
      <button
        class="btn btn-success btn-md float-right"
        :disabled="checkCapabilitiesButtonDisabled"
        @click="$emit('check-capabilities')"
      >
        <span :class="{'d-none': !$store.getters.isLoadingWikiData}">
          <i class="fa fa-sync fa-spin"></i>
        </span>
        <span :class="{'d-none': $store.getters.isLoadingWikiData}">
          <i class="fa fa-search"></i>
        </span>
        &nbsp;Check Capabilities
      </button>
    </inquiry-actions>
  </div>
</template>

<style scoped>
.countriesContainer .form-group input[type="checkbox"].form-control {
  width: 50px;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0.6rem;
}

.countriesContainer .form-group label {
  position: absolute;
  top: 2px;
  left: 70px;
}
</style>
<script>
import InquiryActions from './InquiryActions';
import FeaturesMixin from '@/mixins/features.mixin';
import SanctionsMixin from '@/mixins/sanctions.mixin';

export default {
  name: "CountrySelector",
  components: { InquiryActions },
  mixins: [FeaturesMixin, SanctionsMixin],
  props: {
    checkCapabilitiesButtonDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    continent: "Europe",
		countryFilter: null,
		selectAllCountries: false,
    devMode: process.env.NODE_ENV !== "production",
  }),
  computed: {
    continents() {
      let continents = [
        'All',
        'Africa',
        'Asia Pacific',
        'Europe',
        'America'
      ];
      if (process.env.VUE_APP_CONTINENTS && process.env.VUE_APP_CONTINENTS.length > 0) {
        continents = process.env.VUE_APP_CONTINENTS.split(",");
      }
      return continents.map((continent) => {
        return { label: continent, name: continent };
      });
    },

		filteredCountries() {
			const countries = this.$store.getters.getCountriesByContinent(this.continent);
			if (!this.countryFilter) {
				return countries;
			}
			return countries.filter(country => {
				return country.country_name.toLowerCase().includes(this.countryFilter.toLowerCase());
			});
		}
  },
  methods: {
    changeContinent(value) {
      this.continent = value;
      this.$store.dispatch("loadCountriesForContinent", { continent: value });
    },
    checkCountry(value, event) {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: value,
        checked: event.target.checked
      });
      this.$emit("change-input", { type: "country" });
    },
    checkAllCountries() {

			this.selectAllCountries = this.selectAllCountries !== true;

      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: this.selectAllCountries,
        continent: this.continent
      });
    },
    setDefaultContinent() {
      if (this.usingAMENAElements()) {
        this.continent = "Africa";
      } else if (this.continents.includes('EMEA')) {
        this.continent = 'EMEA';
      }
    },
    sanctionTooltip(severity) {
      if (this.shouldShowSanctionMessage(severity)) {
        return {
          title: this.getSanctionMessage(severity),
          variant: 'danger',
          trigger: 'hover',
        }
      }
      return null;
    }
  },
  mounted() {
    this.setDefaultContinent();

    this.changeContinent(this.continents[0].name);

    this.$store.commit("setLastUrl", { name: this.$router.currentRoute.name });

  }
};
</script>
