var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full h-screen items-center justify-center text-center p-2"},[_c('div',{staticClass:"p-12 border border-gray-300",on:{"dragover":_vm.dragOver,"dragleave":_vm.dragLeave,"drop":_vm.drop}},[_c('br'),_c('h5',[_vm._v(_vm._s(_vm.title))]),_c('hr'),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('input',{ref:"file",staticClass:"w-px h-px opacity-0 overflow-hidden absolute",attrs:{"type":"file","multiple":"","name":'fields[assetsFieldHandle' + _vm.uploaderId + '][]',"id":'assetsFieldHandle' + _vm.uploaderId,"accept":this.acceptedFileTypes},on:{"change":_vm.onChange}}),_c('label',{staticClass:"block cursor-pointer w-100 p-4",attrs:{"for":'assetsFieldHandle' + _vm.uploaderId}},[_vm._v(" Drop files or click here to upload. ")]),(this.fileList.length)?_c('div',{staticClass:"row"},_vm._l((this.fileList),function(file,index){return _c('div',{key:index,staticClass:"col-4"},[_c('b-overlay',{attrs:{"show":file.some(fileVersions => fileVersions.loading === true)}},[_c('div',{staticClass:"card m-2"},[_c('div',{staticClass:"card-header"},[(file[file.length - 1]?.countries?.length === 0)?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" All Countries ")]):_vm._l((_vm.formatCountriesFromArray(
                    file[file.length - 1]?.countries
                  )),function(country,index){return _c('b-badge',{key:index,staticClass:"mr-1",attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(country)+" ")])}),_c('br'),_c('br')],2),_c('div',{staticClass:"card-body"},[_c('editable-file-label',{attrs:{"files":file},on:{"file-label-updated":_vm.updateFileList}}),_c('br'),_c('h6',[_vm._v(_vm._s(file[file.length - 1]?.name))]),_c('span',{staticClass:"text-muted small"},[_vm._v(" Added: "+_vm._s(_vm.$options.filters.formatDateUK( file[file.length - 1]?.document.date_created ))+" ")]),_c('br'),_c('br'),_c('br'),_c('button',{staticClass:"btn btn-secondary btn-sm w-100",attrs:{"type":"button","title":'See ' + file.length + ' version(s)'},on:{"click":function($event){return _vm.openFileVersionListModal(index)}}},[_c('i',{staticClass:"fa fa-list"}),_vm._v(" "+_vm._s(file.length)+" ")])],1)])])],1)}),0):_vm._e(),_c('br')])],1),_c('b-modal',{attrs:{"id":'modal-file-version' + _vm.uploaderId,"hide-footer":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h2',[_c('i',{staticClass:"fa fa-book-open"}),_vm._v(" File Versions")])]},proxy:true}])},[_c('h5',[_vm._v("Are you replacing an existing file or adding an additional file?")]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 offset-4"},[_c('div',{class:{
            card: true,
            'm-2': true,
            'selected-card': _vm.bUploadedFileIsANewFile
          }},[_c('div',{staticClass:"card-header text-center"},[_c('i',{staticClass:"fa fa-2x fa-file"})]),_c('div',{staticClass:"card-body text-center",on:{"click":function($event){return _vm.selectNewFile()}}},[_c('h5',{staticClass:"card-title"},[_c('i',{staticClass:"fa fa-plus-circle"}),_c('br'),_c('br'),_vm._v(" New File ")]),_c('br')])])])]),(_vm.fileList.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapseExample",modifiers:{"collapseExample":true}}],staticClass:"btn btn-secondary w-100"},[_vm._v(" Or replace an existing file... ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapseExample"}},[_c('div',{staticClass:"card card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.fileList),function(file){return _c('div',{key:file[file.length - 1].document_id,staticClass:"col-4"},[_c('div',{class:{
                    card: true,
                    'm-2': true,
                    'selected-card': file[file.length - 1].selected
                  }},[_c('div',{staticClass:"card-header text-center"},[_c('i',{staticClass:"fa fa-2x fa-file"})]),_c('div',{staticClass:"card-body",on:{"click":function($event){return _vm.selectNewFileVersion(file[file.length - 1])}}},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.getFileLabel(file)))]),_c('span',{staticClass:"text-muted small"},[_vm._v(" "+_vm._s(_vm.$options.filters.formatDateUK( file[file.length - 1].document.date_created ))+" ")])])])])}),0)])])],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('br'),_c('hr'),_c('br'),_c('div',[_c('b-form-checkbox',{model:{value:(_vm.bNewFileIsForAllCountries),callback:function ($$v) {_vm.bNewFileIsForAllCountries=$$v},expression:"bNewFileIsForAllCountries"}},[_c('h6',[_vm._v("This file applies to all countries")])])],1),(!_vm.bNewFileIsForAllCountries)?_c('div',[_c('generic-country-selector',{attrs:{"countryList":_vm.countryList},on:{"update:selectedCountries":_vm.updateSelectedCountries}})],1):_vm._e()])]),_c('div',{staticClass:"row"},[_c('br'),_c('hr'),_c('br')]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.upload()}}},[_vm._v("Submit")])],1)])]),_c('uploader-file-version-modal',{attrs:{"customModalId":'modal-file-version-list' + _vm.uploaderId,"fileList":_vm.fileList,"selectedFileVersionsIndex":_vm.selectedFileVersionsIndex},on:{"download":_vm.download,"remove":_vm.remove}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }