import { render, staticRenderFns } from "./TextTruncated.vue?vue&type=template&id=ea1c9820&scoped=true"
import script from "./TextTruncated.vue?vue&type=script&lang=js"
export * from "./TextTruncated.vue?vue&type=script&lang=js"
import style0 from "./TextTruncated.vue?vue&type=style&index=0&id=ea1c9820&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea1c9820",
  null
  
)

export default component.exports