const DownloadsMixin = {
  methods: {
    downloadFile(fileData, fileName) {
      const url = window.URL.createObjectURL(new Blob([fileData]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }
};

export default DownloadsMixin;
