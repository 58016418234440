<template>
    <div id="Threads">

        <div class="row my-3">
            <div class="col">
                <b-button @click="hideThread()">Go Back</b-button>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <card type="primary">
                    <div class="row" id="root">
                        <div class="col">
                            <h2 class="card-title">{{ this.communication.master_policy_title }}</h2>
                            <div class="py-2" v-if="this.communication.posted_by_client_broker"><small>Posted By: {{ this.communication.posted_by_client_broker.full_name }}, {{ this.communication.date_created }}</small></div>
                            <span v-html="this.communication.master_policy_note"></span>
                            <br />
                        </div>
                    </div>
                </card>

                <card type="primary">
                    <div class="row">
                        <div class="col">
                            <h5>Comments <span v-if="this.communication.programme_note_comment">({{ this.communication.programme_note_comment.length }})</span></h5>
                            <div v-for="comment in this.communication.programme_note_comment.slice((this.currentPage-1)*this.perPage,(this.currentPage-1)*this.perPage+this.perPage)"x>
                                <comment :comment="comment"></comment>
                            </div>
                        </div>
                        <small v-if="!this.communication.programme_note_comment">No comments yet. Once somebody leaves a comment, it will appear here.</small>
                    </div>

                    <div class="row">
                        <div class="col">
                            <b-pagination
                                    v-model="currentPage"
                                    :total-rows="this.communication.programme_note_comment.length"
                                    :per-page="perPage"
                                    aria-controls="my-table"
                            ></b-pagination>
                        </div>
                    </div>
                </card>

                <card type="primary">

                    <div class="row">
                        <div class="col">
                    <span v-for="(error, index) in this.errors" :key="index">
                        <small class="text-danger">{{ error }}</small>
                    </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <b-form-textarea validated
                                             id="textarea"
                                             v-model="newComment"
                                             placeholder="Your comment..."
                                             rows="3"
                                             max-rows="6"
                            ></b-form-textarea>
                            <b-button variant="success" class="mt-3" @click="addNewComment()">Post</b-button>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import LoginModal from "@/components/LoginModal.vue";
import Card from "@/components/Cards/Card";
import Comment from "@/components/Communication/Comment";
import axios from "axios";

export default {
    name: "CommunicationDetails",
    props: ['communication'],
    components: {
        LoginModal,
        Card,
        Comment
    },
    data: () => {
        return {
            message: "This is the message",
            loadingAlerts: true,
            newComment: '',
            errors: null,
            // Table settings
            fields: [
                { key: 'local_policy.issuing_office.regional_company_name', label: 'Office', sortable: true },
                { key: 'handler.full_name', label: 'IO Handler', sortable: true },
                { key: 'date_created', label: 'Posted At', sortable: true },
                { key: 'button', label: '', sortable: false },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: 'date_expiry',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        };
    },
    methods: {
        async hideThread()
        {
            this.$emit('hideThread')
        },
        async addNewComment()
        {
            try {
                let { data } = await axios.post(
                    process.env.VUE_APP_API_ENDPOINT + "/programme/comment",
                    {
                        company_id: this.communication.inquiry_progress.company_id,
                        comment: this.newComment,
                        master_policy_note_id: this.communication.master_policy_note_id
                    },
                    {
                        withCredentials: true
                    }
                );

                if(data.success === true) {
                    this.communication.programme_note_comment.unshift(data.newComment);
                    this.loadingAlerts = false;
										this.newComment = '';
                }

                if(data.errors)
                {
                    this.errors = data.errors.comment[0];
                }

            } catch (err) {
                console.error(err);
            }
        }
    }
}
</script>