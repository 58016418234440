var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"wiki-td",class:{
      'text-center': _vm.centered, 
      'capabilities-success': _vm.wikiData?.level === 'success',
      'capabilities-warning': _vm.wikiData?.level === 'warning',
      'capabilities-danger': _vm.wikiData?.level === 'danger',
      },on:{"mouseenter":() => _vm.toggleAdditionalInfo(true),"mouseleave":() => _vm.toggleAdditionalInfo(false)}},[(_vm.printStyles && (_vm.featureType === 'Insured Payable Tax %' || _vm.featureType === 'Insured Payable Tax %'))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.wikiData?.additional))])]):(_vm.wikiData?.value && _vm.wikiData?.value.length !== 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.wikiData?.value,_vm.sigFigs + 2))+" ")]):_c('span',[_c('small',[_vm._v(" - ")])]),(_vm.wikiData?.additional)?_c('br'):_vm._e(),(_vm.wikiData?.additional)?_c('button',{staticClass:"btn btn-sm btn-secondary btn-additional",class:{'print-styles': _vm.printStyles},on:{"click":() => _vm.locked = !_vm.locked}},[_c('i',{staticClass:"fa fa-info"})]):_vm._e(),(false)?_c('text-truncated',{attrs:{"text":_vm.wikiData?.additional
          ? _vm.$options.filters.formatCurrency(_vm.wikiData?.additional, _vm.sigFigsAdditional + 2)
          : '',"max-length":30,"element":"small"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }