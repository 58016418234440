<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  >
    <div class="sidebar-wrapper">
      <div class="logo">
        <a href="#" class="simple-text logo__container">
          <div class="logo-img">
            <img :src="clientImgSrc('side-bar-logo.png')" alt="" />
          </div>
          <small v-if="!isAviva">
            {{ title }}
          </small>
        </a>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <!--        <slot>-->
        <sidebar-link
          v-for="(link, index) in sidebarLinks"
          :key="link.name + index"
          :to="link.path"
          :link="link"
        >
        </sidebar-link>
        <!--        </slot>-->
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links">
          <!--          <sidebar-link v-for="(link,index) in sidebarLinks"-->
          <!--                        :key="link.name + index"-->
          <!--                        :to="link.path"-->
          <!--                        :link="link">-->
          <!--          </sidebar-link>-->
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import FeaturesMixin from "@/mixins/features.mixin";

import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink
  },
  mixins: [FeaturesMixin],
  props: {
    title: {
      type: String,
      default: "Vue LBD"
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    backgroundImage: {
      type: String,
      default: "/img/digital-globe-low.jpg"
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        position: 'fixed',
        'z-index': '1000 !important',
      };
    },
    isAviva() {
      return this.isClient(process.env.VUE_APP_ICEDE_CLIENT_AVIVA);
    }
  }
};
</script>
<style>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}
</style>
